export const books = [
  {
    title: "Schreiben mit ChatGPT für Autorinnen und Autoren",
    description: "Von der Ideenfindung bis zur Vermarktung",
    imageName: "chatgpt",
    link: "/buecher/uschtrin-schreiben-mit-chatgpt",
    price: "6.99 EUR",
  },
  {
    title: "Handbuch für Autorinnen und Autoren",
    description:
      "Das Standardwerk für alle, die schreiben. DIE Investition in Ihre Zukunft!",
    imageName: "handbuch",
    link: "/buecher/uschtrin-handbuch-fuer-autorinnen-und-autoren",
    price: "54.90 EUR",
  },
  {
    title: "Zwischen Handwerk und Inspiration",
    description: "Lyrik schreiben und veröffentlichen",
    imageName: "weber",
    link: "/buecher/weber-lyrik-schreiben-und-veroeffentlichen",
    price: "18.90 EUR",
  },
]
