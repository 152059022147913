import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

/** @jsx jsx */
import { jsx, Card, Grid, Heading, Text, Link as ThemeUILink } from "theme-ui"

import * as content from "../content/books.js"

const Page = ({ data, location }) => (
  <Layout>
    <SEO
      location={location}
      title="All unsere Bücher"
      description="Eine Übersicht all unserer Bücher"
    />
    <Heading as="h1" variant="siteTitle">
      Unsere Bücher
    </Heading>
    <Grid variant="default" gap={4} columns={[2, null, 4]}>
      {content.books.map(book => (
        <Card sx={{ textAlign: "center" }}>
          <Link
            to={book.link}
            aria-label={`Link zur Unterseite von "${book.title}"`}
          >
            <Img fluid={data[book.imageName].childImageSharp.fluid} />
          </Link>
          <ThemeUILink as={Link} variant="default" to={book.link}>
            <Heading sx={{ pt: 2, fontSize: 1 }} as="h2">
              {book.title}
            </Heading>
          </ThemeUILink>
          <Text sx={{ fontSize: 0 }}>{book.description}</Text>
          <Text sx={{ pt: 2, fontSize: 0 }}>{book.price}</Text>
        </Card>
      ))}
    </Grid>
  </Layout>
)

export const query = graphql`
  query {
    chatgpt: file(relativePath: { eq: "books/cover_uschtrin_chatgpt.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    handbuch: file(relativePath: { eq: "books/handbuch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    weber: file(relativePath: { eq: "books/cover_uschtrin_weber.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default Page
